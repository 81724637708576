var xhr;

function popupClearAndHide(autocomplete_result) {
    autocomplete_result.innerHTML = "";
    autocomplete_result.style.display = "none";
}

function handleListItemClick(elId, resultId, value) {
    var el = document.getElementById(elId);
    var autocomplete_result = document.getElementById(resultId);
    
    el.value = value;
    autocomplete_result.innerHTML = '';
    autocomplete_result.style.display = 'none';
}

function handleKeyPress(event, el, autocomplete_result, submitCallback) {
    var selected = autocomplete_result.getElementsByClassName("autoselected");
    var selectedChild;
    
    if (selected.length > 0) {
        selectedChild = selected[0];
    }

    var selectedIndex = Array.prototype.indexOf.call(autocomplete_result.children, selectedChild);
    var newSelectedIndex = selectedIndex;

    if (event.keyCode === 13) {
        if (selectedChild) {
            event.preventDefault();
            el.value = selectedChild.innerText;
            selectedChild.classList.remove("autoselected");
            return;
        }
        
        if (submitCallback && typeof submitCallback === "function") {
            event.preventDefault();
            submitCallback(el.value);
        }
    }

    if (event.keyCode === 40) {
        newSelectedIndex++;
    }
    
    if (event.keyCode === 38) {
        newSelectedIndex--;
    }

    if (selectedIndex !== newSelectedIndex && newSelectedIndex < autocomplete_result.children.length) {
        if (selectedChild) {
            selectedChild.classList.remove("autoselected");
        }

        autocomplete_result.children[newSelectedIndex].classList.add("autoselected");
    }
}

function processWords(words, el, autocomplete_result) {
    if (words) {
        var a = new RegExp("^" + el.value, "i");
        for (var x = 0, suggestions = document.createDocumentFragment(), hasElements = false; x < words.length; x++) {
            hasElements = true;
            var suggestionItem = document.createElement("p");
            suggestionItem.innerText = words[x];
            suggestionItem.setAttribute("onclick",
                "handleListItemClick('" + el.id + "','" + autocomplete_result.id + "','" + words[x] + "')");
            suggestions.appendChild(suggestionItem);
        }
        if (hasElements == true) {
            autocomplete_result.innerHTML = "";
            autocomplete_result.style.display = "block";
            autocomplete_result.appendChild(suggestions);
            return;
        }
        popupClearAndHide(autocomplete_result);
    }
}

function updPopup(event, el, autocomplete_result, source) {
    if(!el.value) {
        popupClearAndHide(autocomplete_result);
        return;
    }

    var keycode = event.keyCode;

    var valid = 
        (keycode > 47 && keycode < 58)   || // number keys
            (keycode == 32 || keycode == 8)   || // spacebar or delete
            (keycode > 64 && keycode < 91)   || // letter keys
            (keycode > 95 && keycode < 112)  || // numpad keys
            (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
            (keycode > 218 && keycode < 223) ||   // [\]' (in order)
            (keycode == 229); // Android keys.

    if (!valid) {
        return;
    }

    if (xhr && typeof xhr === "object") {
        xhr.abort();
    }

    xhr = $.get(source + '/' + el.value,
        function (data) {
            processWords(data, el, autocomplete_result);
            xhr = null;
        });
}

function InitializeAutocomplete(label, id, path, submitCallback) {
    var el = document.getElementById(id);

    var tag = document.createElement('div');
    tag.setAttribute('id', label + '_autocomplete_result');
    tag.setAttribute('class', 'autocomplete');
    tag.setAttribute('style', 'display: none;');

    el.insertAdjacentElement("afterend", tag);

    var autocomplete_result = document.getElementById(label + '_autocomplete_result');

    el.addEventListener("keydown", function (e) { handleKeyPress(e, el, autocomplete_result, submitCallback) });
    el.addEventListener("keyup", function(e) { updPopup(e, el, autocomplete_result, path) });
    el.addEventListener("change", function(e) { updPopup(e, el, autocomplete_result, path) });
    el.addEventListener("focus", function(e) { updPopup(e, el, autocomplete_result, path) });
};